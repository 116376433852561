/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDc3vAr1nlNEYsxc7p96Dyq4rxZsLkW5uU",
  "appId": "1:952565956844:web:526057654543ee27a665c9",
  "authDomain": "schooldog-i-bleckley-ga.firebaseapp.com",
  "measurementId": "G-EQC4G643DJ",
  "messagingSenderId": "952565956844",
  "project": "schooldog-i-bleckley-ga",
  "projectId": "schooldog-i-bleckley-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-bleckley-ga.appspot.com"
}
